import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout, PreviewCompatibleImage, Header } from "../components";
import { ImageContainer, SoutienContainer } from "../ui";
import { Text } from "../ui/Texts";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/support.jpg";
import partenaires1 from "../img/Partenaires 1.png";
import partenaires2 from "../img/Partenaires 2.png";

export const SoutiensPageTemplate = ({ title, soutiens }) => (
  <>
    <SEO title={title} />
    <Header title={title} bgImage={bgImage} bgPosition="center 10%" fallback={linearGradients.soutiens} />
    <Wrapper>
      <Container>
      <ImageContainer>
        <PreviewCompatibleImage imageInfo={{
          image: partenaires1,
          alt: "Partenaires",
        }} />
        <PreviewCompatibleImage imageInfo={{
          image: partenaires2,
          alt: "Partenaires",
        }} />
      </ImageContainer>
        {/* {soutiens.map((partner) => (
          <SoutienContainer key={partner.text} mobile>
            <ImageContainer maxWidth="230px" imgLeft margin="5rem" marginMobile="1rem">
              {partner.site ? (
                <a href={partner.site}>
                  <PreviewCompatibleImage imageInfo={partner.image} />
                </a>
              ) : (
                <PreviewCompatibleImage imageInfo={partner.image} />
              )}
            </ImageContainer>
            <Text>{partner.text}</Text>
          </SoutienContainer>
        ))} */}
      </Container>
    </Wrapper>
  </>
);

SoutiensPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  soutiens: PropTypes.array,
};

const SoutiensPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SoutiensPageTemplate title={frontmatter.title} soutiens={frontmatter.soutiens} />
    </Layout>
  );
};

SoutiensPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SoutiensPage;

export const nousConnaitrePageQuery = graphql`
  query SoutiensPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        soutiens {
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          site
          text
        }
      }
    }
  }
`;
